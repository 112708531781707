<template>
  <v-dialog v-model="show" width="450px" height="80vh" persistent>
    <div class="c-dialog">
      <v-card>
        <v-card-text class="relative pb-10 px-10 pt-20">
          <div class="dialog-close" v-on:click="closeDialog" v-ripple>
            <img
              :src="`${baseAppPath}/media/close-icon.png`"
              width="14px"
              height="14px"
            />
          </div>

          <b-row>
            <b-col cols="12" sm="3">
              <div
                :style="{
                  backgroundImage: `url('${elearningUrl}${data.img_location}')`
                }"
                style="background-size: contain; background-position: top center;"
              >
                <b-aspect aspect="1:1" class="w-100"> </b-aspect>
              </div>
            </b-col>

            <b-col cols="12" sm="9">
              <div class="program-title mt-2">{{ data.name }}</div>
              <div class="program-pic">{{ data.code }}</div>
            </b-col>
          </b-row>

          <div class="d-flex align-items-center my-3">
            <div class="program-tag">{{ data.end_date }}</div>
            <div class="program-tag">
              {{ data.active == 1 ? "Aktif" : "Nonaktif" }}
            </div>
          </div>

          <div v-html="data.desc" class="program-desc"></div>

          <router-link
            class="text-white"
            :to="`/peningkatan-kapasitas/detail/${data.id}`"
          >
            <button
              class="btn btn-secondary mx-auto d-block font-weight-bolder px-6 py-3 mt-5"
              style="border-radius: 10px"
              v-ripple
            >
              Lihat Detil Program
            </button>
          </router-link>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
* {
  font-family: "Aller";
  font-size: 14px;
}

.program-title {
  margin-bottom: 10px;
  line-height: 22px;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  color: #8c8c8c;
}
.program-pic {
  font-style: normal;
  color: #a29f9d;
}
.program-duration {
  display: inline-block;
  padding: 4px 12px;
  color: #a29f9d;
  border: 1px solid #d7d4d3;
  border-radius: 40px;
}
.program-desc {
  margin: 20px 0;
}
.program-tag {
  margin-right: 10px;
  padding: 4px 16px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 40px;
  color: #1e9354;
  background-color: rgba(6, 181, 111, 0.22);
}
.program-unit {
  text-align: justify;
}
.program-komponen {
  margin-bottom: 10px;
  padding-left: 12px;
  border-left: 6px solid;

  .program-komponen-title {
    margin-bottom: 4px;
    font-size: 14px;
  }
  .program-komponen-desc {
    margin-bottom: 4px;
    font-size: 13px;
    color: #a6a5a5;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
}
</style>

<script>
import Moment from "moment";

import ApiService from "@/core/services/api.service";

export default {
  props: ["data", "show", "closeDialog"],
  data() {
    return {
      baseUrl: ApiService.baseUrl,
      elearningUrl: ApiService.elearningUrl,
      routesName: ["Program"],
      activeTabIndex: 0,

      dialog: false,
      confirmationDialog: false
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    setBantuActiveTab(name, index) {
      this.activeTab = name;
      this.activeTabIndex = index;
    },
    setActiveTabIndex(index) {
      this.activeTabIndex = index;
    },
    formatDate(date) {
      return Moment(date).format("DD MMM YYYY [|] HH:mm [WIB]");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    }
  }
};
</script>
