<template>
  <div>
    <div class="breadcrumb-text">
      Pelatihan / {{ routesName[activeTabIndex] }}
    </div>

    <div class="tab-container">
      <div
        v-ripple
        class="tab-item"
        :class="{ 'tab-item-active': activeTabIndex == 0 }"
        v-on:click="setActiveTabIndex(0)"
      >
        Daftar Pelatihan
      </div>
      <div
        v-ripple
        class="tab-item"
        :class="{ 'tab-item-active': activeTabIndex == 1 }"
        v-on:click="setActiveTabIndex(1)"
      >
        Kotak Sampah
      </div>
    </div>

    <div v-if="activeTabIndex === 0">
      <div class="table-container">
        <div class="add-btn-wrapper">
          <img
            :src="`${baseAppPath}/media/add-btn-wrapper.png`"
            width="180px"
          />
        </div>
        <div class="add-btn" v-on:click="dialog = true" v-ripple>
          <img :src="`${baseAppPath}/media/add-icon.png`" />
        </div>

        <AkunTable
          :dialog="dialog"
          :openDialog="() => (dialog = true)"
          :closeDialog="() => (dialog = false)"
          :confirmationDialog="confirmationDialog"
          :cofirmationDialogOpened="opened => (confirmationDialog = opened)"
        />
      </div>
    </div>

    <div v-if="activeTabIndex === 1">
      <div class="table-container">
        <RoleTable
          :dialog="dialog"
          :openDialog="() => (dialog = true)"
          :closeDialog="() => (dialog = false)"
          :confirmationDialog="confirmationDialog"
          :cofirmationDialogOpened="opened => (confirmationDialog = opened)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.btn-group {
  width: 100%;

  .dropdown-toggle.nav-link:after,
  .dropdown-toggle.btn:after {
    position: absolute !important;
    right: 15px !important;
    top: 41% !important;
  }
}
.c-datatable {
  table {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    border-bottom-width: thin;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);

    thead {
      tr {
        th {
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 11.5px !important;
          color: #a29f9d !important;
        }
      }
    }
    tbody {
      tr {
        td {
          height: 40px !important;
          font-style: normal !important;
          font-weight: bold !important;
          font-size: 12px !important;
          color: #6c6867 !important;
        }
      }
    }
  }
  .custom-switch {
    display: inline-block;
    transform: scale(1.7) translateX(10px);
  }
  .c-datatable-actions {
    & > div {
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: #f0efef;
      cursor: pointer;
    }
    &div:nth-last-child {
      margin-right: 0px;
    }

    .c-datatable-view:hover {
      background-color: #2b97d4;

      img {
        filter: brightness(100);
      }
    }
    .c-datatable-edit:hover {
      background-color: #12d76d;

      img {
        filter: brightness(100);
      }
    }
    .c-datatable-delete:hover {
      background-color: #e32910;

      img {
        filter: brightness(100);
      }
    }

    img {
      height: 12px;
    }
  }
}

.c-max-lines-1 {
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.c-max-lines-2 {
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.c-max-lines-3 {
  display: -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>

<style lang="scss">
.tab-container {
  display: flex;
  margin-bottom: 15px;

  .tab-item {
    padding: 5px 28px;
    margin-right: 10px;
    color: #06b56f;
    font-size: 12px;
    border: 1px solid #06b56f;
    border-radius: 8px;
    cursor: pointer;
    transition: all 400ms;
    -webkit-box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);
    -moz-box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);
    box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);

    &:hover {
      -webkit-box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
      -moz-box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
      box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
    }
  }
  .tab-item-active {
    color: #ffffff;
    font-weight: bold;
    background: #06b56f;
    -webkit-box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63);
    -moz-box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63);
    box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63);
  }
}

.table-container {
  position: relative;
  min-height: 80vh;
  padding: 20px;
  margin-right: 20px;
  border-radius: 16px;
  background-color: #ffffff;
  -webkit-box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
  -moz-box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
  box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);

  .add-btn-wrapper {
    position: absolute;
    top: -38px;
    right: -37px;
  }
  .add-btn {
    position: absolute;
    top: -15px;
    right: -13px;
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;
    background-color: #06b56f;
    z-index: 5;
    -webkit-box-shadow: 1px 5px 11px 0px rgba(6, 181, 111, 0.3);
    -moz-box-shadow: 1px 5px 11px 0px rgba(6, 181, 111, 0.3);
    box-shadow: 1px 5px 11px 0px rgba(6, 181, 111, 0.3);

    img {
      height: 24px;
      width: 24px;
    }

    &:hover {
      background-color: #0fe17a;
    }
  }
}

.c-dialog {
  position: relative;

  .dialog-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #8c8c8c;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .input-title {
    margin-bottom: 6px;
    color: #8c8c8c;
    font-size: 12px;
  }
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-radius: 8px !important;
  }
  .btn-group {
    width: 100%;

    .dropdown-toggle.nav-link:after,
    .dropdown-toggle.btn:after {
      position: absolute !important;
      right: 15px !important;
      top: 41% !important;
    }
  }
}
.dialog-close {
  position: absolute;
  top: 14px;
  right: 14px;
  padding: 14px;
  border-radius: 100px;
  background-color: #ffffff;
  cursor: pointer;
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
}
</style>

<script>
import Moment from "moment";

import ApiService from "@/core/services/api.service";

import AkunTable from "./PelatihanTable.vue";
import RoleTable from "./PelatihanTrashTable.vue";

export default {
  name: "peningkatan_kapasitas",
  components: {
    AkunTable,
    RoleTable
  },
  data() {
    return {
      baseUrl: ApiService.baseUrl,
      routesName: ["Daftar Pelatihan", "Kotak Sampah Pelatihan"],
      activeTabIndex: 0,

      dialog: false,
      confirmationDialog: false,
      dialogRole: false,
      confirmationDialogRole: false
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    setBantuActiveTab(name, index) {
      this.activeTab = name;
      this.activeTabIndex = index;
    },
    setActiveTabIndex(index) {
      this.activeTabIndex = index;
    },
    formatDate(date) {
      return Moment(date).format("DD MMM YYYY [|] HH:mm [WIB]");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    }
  }
};
</script>
