<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="per-page-wrapper">
        <div>Show:</div>
        <div style="width: 1px; background: #D7D4D3"></div>
        <div>
          <b-dropdown size="sm" variant="link" right no-flip>
            <template v-slot:button-content>
              <div style="margin-left: -15px">{{ perPage }}</div>
            </template>

            <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
            <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
            <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
            <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div>
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="data"
        :sort-by.sync="columnName"
        :sort-desc.sync="isDescending"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.desc`]="{ item }">
          <div class="c-max-lines-1">
            {{ item.desc }}
          </div>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <div>
            {{ status(item.end_date) }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-view"
              v-on:click="restoreData(item.id)"
            >
              <img :src="`${baseAppPath}/media/reply-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialog"
        width="400px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{ selectedData.name ? "Edit akun" : "Tambah akun" }}
              </div>

              <b-form class="form" @submit.stop.prevent>
                <div role="alert" class="alert fade alert-danger">
                  <!-- <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div> -->
                </div>

                <b-form-group>
                  <div class="input-title">Nama</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.name.$model"
                    :state="validateState('name')"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Full name is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Email</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    type="email"
                    autocomplete="off"
                    v-model="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="input-2-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-2-live-feedback">
                    Email is required and a valid email address.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  v-if="!selectedData.name"
                  id="example-input-group-4"
                  label=""
                  label-for="example-input-4"
                >
                  <div class="input-title">Password</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    type="password"
                    autocomplete="off"
                    v-model="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    Password minimum length is 6.
                  </b-form-invalid-feedback>
                </b-form-group>

                <!-- <b-form-group>
                  <div class="input-title">Username</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.username.$model"
                    :state="validateState('username')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    Username is required.
                  </b-form-invalid-feedback>
                </b-form-group> -->

                <RoleSelect
                  v-if="dialog"
                  :title="'Role'"
                  placeholder="Select a Role"
                  endpoint="api/roles"
                  :selected="{ id: form.role_id, name: form.role_name }"
                  :onSelected="val => (form.role_id = val.id)"
                />

                <!-- <b-form-invalid-feedback id="input-4-live-feedback">
                  Role is required.
                </b-form-invalid-feedback> -->

                <!-- <b-form-group>
                  <div class="input-title">Contact Person</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.contact.$model"
                    :state="validateState('contact')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    Contact Person is required.
                  </b-form-invalid-feedback>
                </b-form-group> -->

                <button
                  ref="kt_submit"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: showPelatihanDialog ? 'inherit' : '0px' }"
    >
      <PelatihanDialog
        :show="showPelatihanDialog"
        :closeDialog="() => (showPelatihanDialog = false)"
        :data="selectedData"
      />
    </v-app>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: confirmationDialog ? 'inherit' : '0px' }"
    >
      <v-dialog v-model="confirmationDialog" width="400px" persistent>
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div
                class="dialog-close"
                v-on:click="changeStatus(false)"
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title text-primary">
                {{
                  selectedData.active
                    ? "Lajutkan mengaktifkan akun?"
                    : "Lajutkan menonaktifkan akun?"
                }}
              </div>

              <div class="d-flex justify-content-center mt-6">
                <div v-ripple class="tab-item" v-on:click="changeStatus(false)">
                  Tidak
                </div>
                <div
                  v-ripple
                  class="tab-item tab-item-active"
                  v-on:click="changeStatus(true)"
                >
                  Ya
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { validationMixin } from "vuelidate";
import {
  email,
  minLength,
  required,
  requiredIf
} from "vuelidate/lib/validators";
import Moment from "moment";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import RoleSelect from "@/view/components/RoleSelect/RoleSelect.vue";
import PelatihanDialog from "./PelatihanDialog.vue";

export default {
  mixins: [validationMixin],
  props: [
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {
    RoleSelect,
    PelatihanDialog
  },
  data() {
    return {
      search: "",
      columnName: "created_at",
      isDescending: true,
      headers: [
        { text: "Kode", value: "code", width: "80px" },
        { text: "Nama Pelatihan", value: "name", width: "30%" },
        { text: "Deskripsi", value: "desc" },
        { text: "Status", value: "active", width: "90px" },
        { text: "Tahun", value: "year", width: "90px" },
        { text: "Tanggal Berakhir", value: "end_date", width: "140px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      data: [],
      selectedData: {},

      form: {
        name: null,
        email: null,
        password: null,
        role_id: null,
        role_name: null
      },

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      showPelatihanDialog: false
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(1)
      },
      email: {
        required,
        email
      },
      password: {
        required: requiredIf(function() {
          return !this?.selectedData?.name;
        }),
        minLength: minLength(6)
      },
      role_id: {
        required
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      ApiService.get(
        `api/pelatihan?trash&token=${JwtService.getToken("elearning")}`,
        "elearning"
      )
        .then(({ data }) => {
          if (data?.data) {
            this.data = data?.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    restoreData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan memulihkan pelatihan?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.post(
                `api/pelatihan/restore/${id}?token=${JwtService.getToken(
                  "elearning"
                )}`,
                null,
                "elearning"
              )
                .then(() => {
                  var index = _this.data.findIndex(o => o.id === id);
                  if (index !== -1) {
                    let _data = _this.data;
                    _data.splice(index, 1);
                    _this.data = _data;
                  }

                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        name: null,
        email: null,
        password: null,
        role_id: null,
        role_name: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatus(isConfirm) {
      if (!isConfirm) {
        this.selectedData.active = !this.selectedData.active;
      }
      this.cofirmationDialogOpened(false);
    },
    status(end_date) {
      try {
        return Moment().isAfter(end_date) ? "Nonaktif" : "Aktif";
      } catch (error) {
        throw error;
      }
    },
    openConfirmationDialog(item) {
      this.selectedData = item;
      this.cofirmationDialogOpened(true);
    },
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    onPageChanged(page) {
      this.pagination = page;
    }
  }
};
</script>
