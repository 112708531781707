<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="per-page-wrapper">
        <div>Show:</div>
        <div style="width: 1px; background: #D7D4D3"></div>
        <div>
          <b-dropdown size="sm" variant="link" right no-flip>
            <template v-slot:button-content>
              <div style="margin-left: -15px">{{ perPage }}</div>
            </template>

            <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
            <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
            <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
            <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div>
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="data"
        :sort-by.sync="columnName"
        :sort-desc.sync="isDescending"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.desc`]="{ item }">
          <div class="c-max-lines-1">
            {{ strippedContent(item.desc) }}
          </div>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <div>
            {{ status(item.end_date) }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-view"
              v-on:click="
                selectedData = item;
                showPelatihanDialog = true;
              "
            >
              <img :src="`${baseAppPath}/media/view-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                form = { ...form, ...item, img_location: null };
                openDialog();
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialog"
        width="400px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{ selectedData.name ? "Edit Pelatihan" : "Tambah Pelatihan" }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert fade alert-danger">
                  <!-- <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div> -->
                </div>

                <b-form-group>
                  <div class="input-title">Code</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.code.$model"
                    :state="validateState('code')"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Code is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Name</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.name.$model"
                    :state="validateState('name')"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Name is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Deskripsi</div>
                  <Editor v-if="dialog" v-model="form.desc"></Editor>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Logo</div>

                  <b-form-file
                    class="c-file-input form-control form-control-solid h-auto pl-3 pr-7 pb-0"
                    v-model="form.img_location"
                    :state="validateState('img_location')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-file>

                  <div
                    v-if="logoTemp"
                    :style="{ backgroundImage: `url(${logoTemp})` }"
                    class="w-100 overflow-hidden mt-2"
                    style="height: 150px; background-size: contain; background-position: center center; border: 1px solid #E5EAEE; border-radius: 8px"
                  >
                    <b-aspect aspect="1:1" class="w-100"> </b-aspect>
                  </div>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    Logo is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Tahun</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.year.$model"
                    :state="validateState('year')"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Tahun is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Tanggal Berakhir</div>
                  <date-picker
                    v-model="form.end_date"
                    :format="'YYYY-MM-DD'"
                    valueType="format"
                    placeholder="YYYY-MM-DD"
                  ></date-picker>
                </b-form-group>

                <button
                  ref="kt_submit"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: showPelatihanDialog ? 'inherit' : '0px' }"
    >
      <PelatihanDialog
        :show="showPelatihanDialog"
        :closeDialog="() => (showPelatihanDialog = false)"
        :data="selectedData"
      />
    </v-app>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: confirmationDialog ? 'inherit' : '0px' }"
    >
      <v-dialog v-model="confirmationDialog" width="400px" persistent>
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div
                class="dialog-close"
                v-on:click="changeStatus(false)"
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title text-primary">
                {{
                  selectedData.active
                    ? "Lajutkan mengaktifkan Pelatihan?"
                    : "Lajutkan menonaktifkan Pelatihan?"
                }}
              </div>

              <div class="d-flex justify-content-center mt-6">
                <div v-ripple class="tab-item" v-on:click="changeStatus(false)">
                  Tidak
                </div>
                <div
                  v-ripple
                  class="tab-item tab-item-active"
                  v-on:click="changeStatus(true)"
                >
                  Ya
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import Moment from "moment";
import DatePicker from "vue2-datepicker";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Editor from "@/view/components/Editor/Editor.vue";
import PelatihanDialog from "./PelatihanDialog.vue";

export default {
  mixins: [validationMixin],
  props: [
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {
    Editor,
    DatePicker,
    PelatihanDialog
  },
  data() {
    return {
      search: "",
      columnName: "created_at",
      isDescending: true,
      headers: [
        { text: "Kode", value: "code", width: "80px" },
        { text: "Nama Pelatihan", value: "name", width: "30%" },
        { text: "Deskripsi", value: "desc" },
        { text: "Status", value: "active", width: "90px" },
        { text: "Tahun", value: "year", width: "90px" },
        { text: "Tanggal Berakhir", value: "end_date", width: "140px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      data: [],
      selectedData: {},

      form: {
        create_by: 1,
        code: null,
        name: null,
        desc: null,
        img_location: null,
        year: null,
        end_date: null
      },
      logoTemp: null,

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      showPelatihanDialog: false
    };
  },
  validations: {
    form: {
      create_by: {
        required,
        minLength: minLength(1)
      },
      code: {
        required,
        minLength: minLength(1)
      },
      name: {
        required,
        minLength: minLength(1)
      },
      desc: {
        required,
        minLength: minLength(1)
      },
      img_location: {},
      year: {
        required,
        minLength: minLength(1)
      },
      end_date: {
        required,
        minLength: minLength(1)
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      ApiService.get("api/pelatihan", "elearning")
        .then(({ data }) => {
          if (data?.data) {
            this.data = data?.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var formData = new FormData();
      formData.append("create_by", JSON.parse(JwtService.getUserInfo()).id);
      formData.append("code", this.form.code);
      formData.append("name", this.form.name);
      formData.append("desc", this.form.desc);
      formData.append("year", this.form.year);
      formData.append("end_date", this.form.end_date);

      if (this.form.img_location) {
        formData.append("img_location", this.form.img_location);
      }

      if (!this?.selectedData?.name) {
        // Create
        ApiService.post(
          `api/pelatihan?token=${JwtService.getToken("elearning")}`,
          formData,
          "elearning"
        )
          .then(data => {
            if (data) {
              if (data?.status == 200 || data?.status == 201) {
                this.getData();
                this.resetForm();
                this.closeDialog();
                this.$swal.fire({
                  icon: "success",
                  title: "Berhasil!",
                  text: "Pelatihan berhasil ditambahkan"
                });
              }

              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
            }
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        ApiService.post(
          `api/pelatihan/${
            this?.selectedData?.id
          }?_method=PUT&token=${JwtService.getToken("elearning")}`,
          formData,
          "elearning"
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data Pelatihan berhasil diperbarui"
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    deleteData(id) {
      var _this = this;

      this.$swal({
        title: "Lanjutkan menghapus pelatihan?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(
                `api/pelatihan/${id}?token=${JwtService.getToken("elearning")}`,
                "elearning"
              )
                .then(() => {
                  _this.getData();
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        create_by: null,
        code: null,
        name: null,
        desc: null,
        img_location: null,
        year: null,
        end_date: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatus(isConfirm) {
      if (!isConfirm) {
        this.selectedData.active = !this.selectedData.active;
      }
      this.cofirmationDialogOpened(false);
    },
    openConfirmationDialog(item) {
      this.selectedData = item;
      this.cofirmationDialogOpened(true);
    },
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY");
    },
    status(end_date) {
      try {
        return Moment().isAfter(end_date) ? "Nonaktif" : "Aktif";
      } catch (error) {
        throw error;
      }
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    onPageChanged(page) {
      this.pagination = page;
    }
  },

  watch: {
    img_location(val) {
      this.logoTemp = URL.createObjectURL(val);
    }
  },

  computed: {
    img_location() {
      return this.form.img_location;
    }
  }
};
</script>
